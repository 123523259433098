import Alpine from 'alpinejs';
import gsap from 'gsap';

export default function () {
  Alpine.data('nav', () => ({
    menuIsOpen: false,
    isMobile: false,
    menuColumnEls: [],
    menuEl: undefined,
    loaded: false,

    toggleNav() {
      if (!this.menuIsOpen) {
        this.openNav();
      } else {
        this.closeNav();
      }
    },

    openNav() {
      const tl = gsap.timeline();
      tl.fromTo(
        this.menuEl,
        {
          x: '-100%',
          skewX: '10deg',
        },
        {
          x: 0,
          skewX: 0,
          duration: 0.4,
          ease: 'linear',
        }
      );
      tl.fromTo(
        this.menuColumnEls,
        { autoAlpha: 0 },
        {
          autoAlpha: 1,
          delay: (i) => 0.1 * i,
          duration: 1,
        }
      );
      this.menuIsOpen = true;
    },

    closeNav() {
      setTimeout(() => {
        this.menuIsOpen = false;
      }, 250);
    },

    init() {
      setTimeout(() => {
        this.loaded = true;
        this.$refs.bodyEl.style.setProperty('opacity', 1, 'important');
      }, 100);
      this.menuEl = this.$refs.innerNav;
      this.menuColumnEls = gsap.utils.toArray('[data-menu-column]');
      gsap.set(this.menuColumnEls, { autoAlpha: 0 });
    },
  }));
}
