import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Lottie from 'lottie-web';

export default function () {
  if (document.querySelector('[data-page]')?.dataset.page !== 'frontpage') return;

  const animationContainers = document.querySelectorAll('[data-fp-lottie-id]');

  animationContainers.forEach((container) => {
    const animation = Lottie.loadAnimation({
      container: container,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: `/dist/lottie/${container.dataset.fpLottieId}.json`,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice',
        className: 'frontpage-lottie-animation',
      },
    });
  });

  gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

  let mm = gsap.matchMedia();
  let sectionHeight = 0;

  mm.add('(min-width: 1024px)', (context) => {
    const scrollNotice = document.querySelector('[data-scroll-notice]');
    let sections = gsap.utils.toArray('[data-section]'),
      currentSection = sections[0];

    gsap.defaults({ overwrite: 'auto', duration: 0.3 });

    // stretch out the body height according to however many sections there are.
    gsap.set('body', { height: sections.length * 100 + '%' });

    // create a ScrollTrigger for each section
    sections.forEach((section, i) => {
      sectionHeight = section.getBoundingClientRect().height;

      ScrollTrigger.create({
        // use dynamic scroll positions based on the window height (offset by half to make it feel natural)
        start: () => (i - 0.5) * innerHeight,
        end: () => (i + 0.5) * innerHeight,
        // when a new section activates (from either direction), set the section accordinglyl.
        onToggle: (self) => self.isActive && setSection(section),
      });
    });

    function setSection(newSection) {
      if (newSection !== currentSection) {
        gsap.to(currentSection, { scale: 0.8, autoAlpha: 0 });
        gsap.to(newSection, { scale: 1, autoAlpha: 1, zIndex: 20 });
        currentSection = newSection;
        setActiveNavLink(newSection);
        if (newSection.dataset.lastSection) {
          scrollNotice.classList.add('opacity-0');
        } else {
          scrollNotice.classList.remove('opacity-0');
        }
      }
    }

    const allNavLinks = gsap.utils.toArray('[data-fp-nav-link]');

    scrollNotice.addEventListener('click', () => {
      window.scrollBy(0, sectionHeight);
    });

    function setActiveNavLink(section) {
      const id = section.dataset.section;
      if (!id) return;
      allNavLinks.forEach((link) => link.parentNode.classList.add('opacity-60'));
      const link = document.querySelector(`a[href="#${id}"]`);
      link.parentNode.classList.remove('opacity-60');
    }

    allNavLinks.forEach((a, index) => {
      a.addEventListener('click', (e) => {
        e.preventDefault();
        allNavLinks.forEach((link) => link.parentNode.classList.add('opacity-60'));
        a.parentNode.classList.remove('opacity-60');
        gsap.to(window, { duration: 0, scrollTo: sectionHeight * a.dataset.fpNavLink, overwrite: 'auto' });
      });

      return () => {
        gsap.set('body', { height: 'initial' });
        sections.forEach((section) => {
          gsap.set(section, { autoAlpha: 1, scale: 1, zIndex: 0 });
        });
      };
    });
  });

  // handles the infinite part, wrapping around at either end....
  // ScrollTrigger.create({
  //   start: 1,
  //   end: () => ScrollTrigger.maxScroll(window) - 1,
  //   onLeaveBack: (self) => self.scroll(ScrollTrigger.maxScroll(window) - 2),
  //   onLeave: (self) => self.scroll(2),
  // }).scroll(2);

  // const observer = new IntersectionObserver(([e]) => {
  //   console.log('doinit');
  //   e.target.classList.toggle('is-pinned', e.intersectionRatio < 1),
  //     {
  //       threshold: [1],
  //     };
  // });

  // const tl = gsap.timeline({
  //   scrollTrigger: {
  //     trigger: 'header',
  //     start: 'top',
  //     scrub: true,
  //     end: 'bottom',
  //     pin: true,
  //     markers: true,
  //   },
  // });

  // tl.to('[data-gsap="page-heading"]', { opacity: 0 });
  // tl.to('[data-gsap="circle-large"]', { scale: 1.5, x: -500, y: -100, opacity: 0 }, '<');
  // tl.to('[data-gsap="circle-small"]', { scale: 0.75, x: 150, y: 100, opacity: 0 }, '<');
  // tl.to('[data-gsap="page-header-lottie"]', { opacity: 0 }, '<');

  // gsap.set('[data-gsap="section-1"] [data-gsap]', { autoAlpha: 0 });

  // const pageTitles = document.querySelectorAll('[data-gsap^="section"] [data-gsap="title"] h2');

  // pageTitles.forEach((title) => {
  //   gsap.to(title, {
  //     scrollTrigger: {
  //       trigger: title,
  //       start: 'top',
  //       end: 'bottom',
  //       toggleActions: 'play none none reverse',
  //     },
  //     fontSize: '30px',
  //     marginLeft: 0,
  //     width: '380px',
  //     duration: 1,
  //   });
  // });

  // const sections = gsap.utils.toArray('section[data-gsap^="section"], header[data-gsap^="section"]');

  // sections.forEach((section) => {});

  // const tl1 = gsap.timeline({
  //   scrollTrigger: {
  //     trigger: '[data-gsap="section-1"]',
  //     start: 'top',
  //     end: 'bottom',
  //     markers: true,
  //   },
  // });

  // tl1.to('[data-gsap="section-1"] [data-gsap="title"]', { autoAlpha: 1, duration: 1 });
  // tl1.to(
  //   '[data-gsap="section-1"] [data-gsap="title"] h2',
  //   {
  //     duration: 1,
  //   },
  //   ''
  // );
  // tl1.to('[data-gsap="section-1"] [data-gsap="text"]', { autoAlpha: 1, duration: 2 }, '<');

  // tl1.to('[data-gsap="section-1"],[data-gsap="section-inner"]', { autoAlpha: 1 });
  // tl1.to('[data-gsap="title"]', { autoAlpha: 1 });

  // gsap.set('[data-gsap="section-1"] [data-gsap="title"]', { backgroundColor: 'red' });

  // // tls1.to('[data-gsap="section-1"] [data-gsap="section-title"]', { margin: 0, fontSize: '14px' });
  // const container = document.querySelector('[data-page]');

  // const innerSections = gsap.utils.toArray('div[data-gsap^="section-inner"]');

  // let totalHeight = 0;
  // innerSections.forEach((section) => {
  //   totalHeight = totalHeight + gsap.getProperty(section, 'height');
  // });

  // gsap.set(container, { height: totalHeight + 'px' });
  // gsap.set(sections, { position: 'fixed', left: 0, top: 0, height: '100%', width: '100%', autoAlpha: 0 });
  // gsap.set(sections, { autoAlpha: 0 });

  // gsap.timeline({
  //   scrollTrigger: {
  //     trigger: container,
  //     start: 1,
  //     end: 'bottom',
  //     markers: true,
  //   },
  // });

  // create a ScrollTrigger for each section
  // sections.forEach((section) => {
  //   ScrollTrigger.create({
  //     trigger: section,
  //     start: 'top',
  //     end: 'bottom',
  //     pin: true,
  //     markers: true,
  //     onEnter: enterSection(section),
  //   });
  // });

  // function enterSection(section) {
  //   gsap.to(section, { opacity: 1 });
  // }

  // Init Slider
  initSlider();
}

function initSlider() {
  // const swiper = new Swiper('#js-problem-swiper', {
  //   speed: 400,
  //   spaceBetween: 100,
  // });
}
