import Swiper from 'swiper';
import { EffectFade } from 'swiper';

export default () => ({
  swiper: undefined,
  currentIndex: 0,

  slideTo(index) {
    this.swiper.slideTo(index);
  },

  init() {
    this.swiper = new Swiper(this.$refs.container, {
      modules: [EffectFade],
      slidesPerView: 1,
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },

      on: {
        activeIndexChange: (e) => {
          this.currentIndex = e.activeIndex;
        },
      },
    });
  },
});
