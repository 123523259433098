import 'htmx.org';
import Alpine from 'alpinejs';
import { lottieHeaderAnimation } from './lottie';
import frontpage from './frontpage';
import problemSlider from './problem-slider';
import aboutGallery from './about-gallery';
import mainNavigation from './main-navigation';

window.htmx = require('htmx.org');

mainNavigation();

Alpine.data('problemSlider', problemSlider);
Alpine.data('aboutGallery', aboutGallery);

window.Alpine = Alpine;
Alpine.start();

// Init lottie animation in page headers
lottieHeaderAnimation();

// Init frontpage scripts
frontpage();
