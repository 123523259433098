import Swiper, { Navigation } from 'swiper';

export default () => ({
  swiper: undefined,

  slideTo(index) {
    this.swiper.slideTo(index);
  },

  init() {
    this.swiper = new Swiper(this.$refs.container, {
      modules: [Navigation],
      slidesPerView: 1,
      spaceBetween: 5,
      loop: true,
      // pagination: {
      //   el: this.$refs.pagination,
      //   clickable: true,
      // },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        640: {
          slidesPerView: 3,
        },
      },
    });
  },
});
