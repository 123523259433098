import Lottie from 'lottie-web';

export function lottieHeaderAnimation() {
  const headerLottie = document.querySelector('header [data-lottie-id]');
  if (!headerLottie) return;

  const animation = Lottie.loadAnimation({
    container: headerLottie,
    renderer: 'svg',
    loop: true,
    autoplay: true,
    path: `/dist/lottie/${headerLottie.dataset.lottieId}.json`,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
      className: 'header-lottie-animation',
      viewBoxOnly: true,
    },
  });
}
